body {
  background-color: black;
}

.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
  /* background: rgb(113, 226, 113); */
}
.payment_header {
  background-color: rgb(49, 49, 49);
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}
#conclave-logo {
  padding-top: 18px;
  padding-left: 18px;
}
.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  /* background:#fff; */
  background: rgb(113, 226, 113);
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 40px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  background-image: linear-gradient(to right, #399bff, #ee71f9);
  border-radius: 30px;
  padding: 10px 20px;
  /* background:rgba(255,102,0,1); */
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}

.bg-modal1 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.747);
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 250;
}

@media only screen and (min-width: 765px) {
  .payment {
    border: 1px solid #f2f2f2;
    width: 444px;
    height: 280px;
    margin: auto;
    border-radius: 20px;
    background: #fff;
  }
  .row {
    margin-left: -227px;
    margin-right: 0%;
  }
}
